export default {
  // Login
  emailIsRequired: {
    english: "Email is a required field",
    spanish: "El correo electrónico es un campo obligatorio",
  },
  enterValidEmail: {
    english: "Please enter a valid email",
    spanish: "Por favor introduzca una dirección de correo electrónico válida",
  },
  passwordIsRequired: {
    oenglish: "Password is a required field",
    spanish: "La contraseña es un campo requerido",
  },
  login: {
    english: "Login",
    spanish: "Acceso",
  },
  firstTimeLogin: {
    english: "First time login",
    spanish: "Inicio de sesión por primera vez",
  },
  forgotPassword: {
    english: "Forgot Password?",
    spanish: "¿Has olvidado tu contraseña?",
  },
  agreeToTerms: {
    english:
      "By using this site, you accept and agree to our following terms of use and privacy policy found below",
    spanish:
      "Al utilizar este sitio, acepta y acepta nuestros siguientes términos de uso y política de privacidad que se encuentran a continuación",
  },
  termsOfUse: {
    english: "Terms Of Use",
    spanish: "Condiciones de uso",
  },
  // Forgot Password
  sendResetPasswordEmail: {
    english: "Send Reset Password Email",
    spanish: "Enviar correo electrónico para restablecer contraseña",
  },
  backToLogin: {
    english: "Back to Login",
    spanish: "Atrás para iniciar sesión",
  },
  //Register
  register: {
    english: "Register",
    spanish: "Registrarse",
  },
  pleaseEnterEmail: {
    english: "Please enter email",
    spanish: "Por favor ingrese su correo electrónico",
  },
  pleaseEnterDoB: {
    english: "Please enter date of birth",
    spanish: "Ingrese la fecha de nacimiento",
  },
  createAccount: {
    english: "Create Account",
    spanish: "Crear una cuenta",
  },
  registerAccount: {
    english: "Register Account",
    spanish: "Registrar Cuenta",
  },
  dateOfBirth: {
    english: "Date of Birth",
    spanish: "Fecha de cumpleaños",
  },
};
