import isEmail from "validator/lib/isEmail";

export const isValidEmail = (email = "") => {
  if (isEmail(email)) {
    return true;
  } else {
    return false;
  }
};

export const isValidZip = zip =>
  !/^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(zip) ? false : true;

export const isValidPhoneNumber = num => {
  if (!num || num.length === 0) {
    return true;
  } else {
    return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(num);
  }
};

export const PASSWORD_REQUIREMENTS =
  "Password must be at least 8 characters long with 1 number, 1 special character, 1 capital and 1 lowercase letter";
export const PASSWORD_EXP = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_-]).{8,}/;
export const isValidPassword = pass => PASSWORD_EXP.test(pass);
