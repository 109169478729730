import browserHistory from "browser-history";
import {
  loginSuccess,
  loginError,
  logout,
  forgotPasswordSuccess,
  forgotPasswordError,
  registerAccountSuccess,
  registerAccountError,
  setLanguage
} from "reducers/auth";
import { NotificationManager } from "react-notifications";
import { fetchPatientNotifications } from "./notifications";
import { setNotificationScreenVisited, setPatientLastBrowse } from "reducers/notifications";
import moment from "moment";
import  secureLocalStorage from "react-secure-storage";

const saveCookieData = user_details => {
  try {
    const data = { company_name: user_details.company_name };
    document.cookie = `${encodeURIComponent(JSON.stringify(data))}`;
  } catch (e) {
    console.log(e);
  }
};

export const login =
  ({ email, password, device_token, rememberMe = false }) =>
  async dispatch => {
    try {
      const {
        user_details,
        authentication_token,
        app_permissions,
        show_recall_prompt,
        timezone,
        user_timezone_last_login
      } = await dispatch({
        type: "API",
        route: "patient_portal/mobile/sign_in",
        method: "POST",
        publicRoute: true,
        body: {
          email,
          password,
          device_token,
          rememberMe,
          platform: "Web Portal"
        }
      });
      dispatch(
        loginSuccess({
          authentication_token,
          user_details,
          app_permissions,
          show_recall_prompt,
          timezone,
          user_timezone_last_login
        })
      );
      dispatch(fetchPatientNotifications())

      if(user_timezone_last_login)
        dispatch(setPatientLastBrowse(moment(user_timezone_last_login).format("YYYY-MM-DD HH:mm:ss")));
      else
        dispatch(setPatientLastBrowse(moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss")));

      saveCookieData(user_details);

      if(rememberMe){
        secureLocalStorage.setItem("rememberMe", email)
      } else {
        secureLocalStorage.removeItem("rememberMe")
      }
      
      browserHistory.push("/");
    } catch (error) {
      if (error?.response?.status === 409) {
        browserHistory.push({
          pathname: "/password-expired",
          state: { email, current_password: password }
        });
        return;
      }
      dispatch(loginError(error.message));
    }
  };

export const forgotPassword =
  ({ email }) =>
  async dispatch => {
    try {
      const { message, success } = await dispatch({
        type: "API",
        route: "patient_portal/mobile/forgot_password",
        method: "POST",
        publicRoute: true,
        body: {
          email
        }
      });
      dispatch(forgotPasswordSuccess({ message, success }));
      browserHistory.push("/login");
    } catch (error) {
      dispatch(forgotPasswordError(error.message));
    }
  };

export const updateLanguage = language => async dispatch => {
  try {
    await dispatch({
      type: "API",
      route: "patient_portal/mobile/users/profile/language",
      method: "PUT",
      body: language
    });
    dispatch(setLanguage(language));
  } catch (error) {
    // dispatch(forgotPasswordError(error.message));
  }
};

export const loginWithToken =
  ({ token, device_token, redirectTo }) =>
  async dispatch => {
    try {
      const { user_details, authentication_token, app_permissions, timezone } =
        await dispatch({
          type: "API",
          route: "patient_portal/mobile/single_use_sign_in",
          method: "POST",
          publicRoute: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: {
            device_token,
            platform: "Web Portal"
          }
        });
      dispatch(
        loginSuccess({ authentication_token, user_details, app_permissions, timezone })
      );
      if (redirectTo) browserHistory.push(redirectTo);
      else browserHistory.push("/");
    } catch (error) {
      dispatch(loginError("Invalid link"));
      browserHistory.push("/login");
    }
  };

export const updateLanguageWithToken = token => async dispatch => {
  try {
    const { message, success } = await dispatch({
      type: "API",
      route: "patient_portal/mobile/users/profile/update_language",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    if (success) {
      NotificationManager.success("Your account was updated");
      browserHistory.push("/login");
      return message;
    } else {
      NotificationManager.error("Update Language Error", message);
      return message;
    }
  } catch (error) {
    console.error(error);
    NotificationManager.error("Update Language Error", error?.message);
  }
};
export const logoutUser = () => dispatch => {
  dispatch(setNotificationScreenVisited(false))
  dispatch(logout());
  browserHistory.push("/login");
};

export const registerAccount = body => async dispatch => {
  try {
    const { status, message } = await dispatch({
      type: "API",
      route: "patient_portal/request_credentials",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body
    });
    if (status === "success" || status === "pending") {
      dispatch(registerAccountSuccess({ message, success: true }));
      browserHistory.push({
        pathname: "/thanks",
        state: { message, backTo: "/login" }
      });
    } else {
      dispatch(registerAccountError(message));
      NotificationManager.error("Sign Up Error", message);
    }
  } catch (error) {
    console.error(error);
    dispatch(registerAccountError(error.message));
  }
};

export const resetPassword = payload => async dispatch => {
  try {
    const { message, success } = await dispatch({
      type: "API",
      route: "patient_portal/mobile/reset_password",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload.tokenString}`
      },
      publicRoute: true,
      body: payload
    });
    if (success) {
      NotificationManager.success("Your Password has been reset");
      browserHistory.push("/login");
      return message;
    } else {
      NotificationManager.error("Reset Password Error", message);
      return message;
    }
  } catch (error) {
    console.error(error);
    NotificationManager.error("Reset Password Error", error?.message);
  }
};

export const resetExpiredPassword =
  ({ email, current_password, password, password_confirmation }) =>
  async dispatch => {
    try {
      const { message, success } = await dispatch({
        type: "API",
        route: "patient_portal/mobile/reset_expired_password",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        publicRoute: true,
        body: {
          email,
          current_password,
          password,
          password_confirmation
        }
      });
      if (success) {
        NotificationManager.success("Your Password has been updated");
        await dispatch(
          login({
            email,
            password,
            rememberMe: false
          })
        );
      } else {
        NotificationManager.error("Reset Password Error", message);
      }
    } catch (error) {
      console.error(error);
      NotificationManager.error("Reset Password Error", error?.message);
    }
  };

export const checkToken = token => async dispatch => {
  try {
    const { success } = await dispatch({
      type: "API",
      route: "patient_portal/mobile/token_check",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      publicRoute: true
    });
    return success;
  } catch (error) {
    return false;
  }
};
export const checkAuth = auth => async dispatch => {
  try {
    const { success } = await dispatch({
      type: "API",
      route: "patient_portal/mobile/auth_check",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`
      },
      publicRoute: true
    });
    return success;
  } catch (error) {
    return false;
  }
};

export const checkTokenAndDob =
  (token, { dateOfBirth, numberOfAttempts }) =>
  async dispatch => {
    try {
      const response = await dispatch({
        type: "API",
        route: "patient_portal/mobile/confirm_dob",
        method: "POST",
        body: {
          dateOfBirth,
          numberOfAttempts
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        publicRoute: true
      });
      return response;
    } catch (error) {
      return {
        success: false,
        message: error.message
      };
    }
  };

  export const checkAuthAndDob =
  (auth, { dateOfBirth, numberOfAttempts }) =>
  async dispatch => {
    try {
      const response = await dispatch({
        type: "API",
        route: "patient_portal/mobile/confirm_dob_auth",
        method: "POST",
        body: {
          dateOfBirth,
          numberOfAttempts
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth}`
        },
        publicRoute: true
      });
      return response;
    } catch (error) {
      return {
        success: false,
        message: error.message
      };
    }
  };

  